.form-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .result-wrapper {
    margin-top: 20px;
    text-align: center;
  }

  .copyright {
    margin-top: 10px;
    font-size: 12px;
    color: gray;
  }

  .copy-notification {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ddd;
    color: #c70f15;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0;
    animation: fade-in 0.3s ease-in-out forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .input-field {
    flex: 1;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #ccc;
    border-right: none;
    -webkit-text-size-adjust: none;
  }
  
  .submit-button {
    padding: 10px 20px;
    border-radius: 0 5px 5px 0;
    background-color: #ddd;
    color: #c70f15;
    border: none;
    font-size: 16px;
  }

  .submit-button:hover {
    background-color: rgb(145, 145, 145);
  }
  
  .loading-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 20px;
  }
  
  .result-list {
    list-style: none;
    padding: 0;
  }
  
  .result-item {
    margin-bottom: 10px;
  }