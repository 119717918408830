.clear-all-button {
    border: none;
    background-color: #ddd;
    color: #c70f15;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .clear-all-button:hover {
    background-color: rgb(145, 145, 145);
  }
  
  .clear-all-button:active {
    background-color: rgb(145, 145, 145);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  